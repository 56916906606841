/*-----------------------------------*\
  #style.css
\*-----------------------------------*/

/**
 * copyright 2022 codewithsadee
 */

/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {
  /**
   * colors
   */

  --st-patricks-blue: hsl(236, 57%, 28%);
  --amaranth-purple: hsl(335, 88%, 38%);
  --royal-blue-dark: hsl(231, 68%, 21%);
  --chrome-yellow: hsl(39, 100%, 52%);
  --space-cadet-1: hsl(230, 41%, 25%);
  --space-cadet-2: hsl(230, 59%, 16%);
  --winter-sky_50: hsla(335, 87%, 53%, 0.5);
  --purple-navy: hsl(236, 26%, 43%);
  --ksu-purple: hsl(275, 54%, 33%);
  --winter-sky: hsl(335, 87%, 53%);
  --razzmatazz: hsl(335, 87%, 51%);
  --platinum: hsl(0, 0%, 90%);
  --black_70: hsla(0, 0%, 0%, 0.7);
  --rajah: hsl(29, 99%, 67%);
  --white: hsl(0, 0%, 100%);

  --gradient-1: linear-gradient(
    90deg,
    var(--royal-blue-dark) 0,
    var(--ksu-purple) 51%,
    var(--royal-blue-dark)
  );
  --gradient-2: linear-gradient(90deg, var(--razzmatazz), var(--rajah));

  /**
   * typography
   */

  --ff-source-sans-pro: "Source Sans Pro", sans-serif;
  --ff-poppins: "Poppins", sans-serif;

  --fs-1: 4.2rem;
  --fs-2: 3.8rem;
  --fs-3: 3.2rem;
  --fs-4: 2.5rem;
  --fs-5: 2.4rem;
  --fs-6: 2rem;
  --fs-7: 1.8rem;
  --fs-8: 1.5rem;

  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  /**
   * border radius
   */

  --radius-4: 4px;
  --radius-12: 12px;

  /**
   * spacing
   */

  --section-padding: 20px;

  /**
   * transition
   */

  --transition-1: 0.15s ease;
  --transition-2: 0.35s ease;
  --cubic-in: cubic-bezier(0.51, 0.03, 0.64, 0.28);
  --cubic-out: cubic-bezier(0.33, 0.85, 0.56, 1.02);

  /**
   * shadow
   */

  --shadow: 0 5px 20px 1px hsla(220, 63%, 33%, 0.1);
}

/*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

a,
img,
span,
input,
button,
ion-icon {
  display: block;
}

button,
input {
  background: none;
  border: none;
  font: inherit;
}

button {
  cursor: pointer;
}

input {
  width: 100%;
}

ion-icon {
  pointer-events: none;
}

img {
  height: auto;
}

address {
  font-style: normal;
}

html {
  font-family: var(--ff-poppins);
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--white);
  color: var(--purple-navy);
  font-size: 1.6rem;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: hsl(0, 0%, 95%);
}

::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 80%);
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(0, 0%, 70%);
}

/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/

.container {
  padding-inline: 15px;
}

.h2,
.h3 {
  font-family: var(--ff-source-sans-pro);
}

.btn {
  background-image: var(--gradient-2);
  background-size: 200%;
  color: var(--white);
  padding: 12px 20px;
  font-size: var(--fs-8);
  font-weight: var(--fw-500);
  border-radius: 0 20px;
  transition: var(--transition-2);
}

.btn:is(:hover, :focus) {
  background-position: right;
}

.w-100 {
  width: 100%;
}

.banner-animation {
  animation: waveAnim 2s linear infinite alternate;
}

@keyframes waveAnim {
  0% {
    transform: translate(0, 0) rotate(0);
  }
  100% {
    transform: translate(2px, 2px) rotate(1deg);
  }
}

.section {
  padding-block: var(--section-padding);
}

.section-title {
  color: var(--st-patricks-blue);
  font-size: var(--fs-3);
  margin-block-end: 60px;
  max-width: max-content;
  margin-inline: auto;
}

.underline {
  position: relative;
}

.underline::before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  height: 6px;
  background-image: var(--gradient-2);
  border-radius: 10px;
}

:is(.service-card, .features-card) .title {
  color: var(--st-patricks-blue);
  font-size: var(--fs-4);
  font-weight: var(--fw-700);
}

:is(.service-card, .features-card, .blog-card) .text {
  font-size: var(--fs-8);
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*-----------------------------------*\
  #HEADER
\*-----------------------------------*/

.header .btn {
  display: none;
}

.header {
  --color: var(--white);

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-block: 14px;
  z-index: 4;
  transition: var(--transition-1);
}

.header.active {
  --color: var(--st-patricks-blue);
  position: fixed;
  box-shadow: 0 2px 30px hsla(0, 0%, 0%, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.logo {
  color: var(--color);
  font-family: var(--ff-source-sans-pro);
  font-size: var(--fs-3);
  display: flex;
}

.nav-open-btn {
  color: var(--color);
  font-size: 32px;
  padding: 4px;
}

.navbar {
  background-color: var(--white);
  position: fixed;
  top: 0;
  left: -280px;
  width: 100%;
  max-width: 280px;
  min-height: 100%;
  padding: 20px;
  visibility: hidden;
  z-index: 2;
  transition: 0.25s var(--cubic-in);
}

.navbar.active {
  transform: translateX(280px);
  visibility: visible;
  transition: 0.5s var(--cubic-out);
}

.navbar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 10px 30px;
}

.navbar-top .logo {
  color: var(--st-patricks-blue);
  font-size: 4.2rem;
  font-weight: var(--fw-700);
}

.nav-close-btn {
  color: var(--space-cadet-1);
  font-size: 2.8rem;
  padding: 4px;
}

.navbar-item:not(:last-child) {
  border-bottom: 1px solid var(--platinum);
}

.navbar-link {
  color: var(--space-cadet-1);
  font-size: var(--fs-8);
  font-weight: var(--fw-600);
  padding-block: 12px;
}

.overlay {
  position: fixed;
  inset: 0;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition-2);
  background-color: "rgba(255, 255, 255, 0.1)";
  backdrop-filter: blur(5px);
}

.overlay.active {
  opacity: 1;
  pointer-events: all;
}

/* addational style */
.single__section {
  background-image: url("./images/hero-bg-bottom.png"),
    url("./images/hero-bg.png"), var(--gradient-1);
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: -280px bottom, center, center;
  background-size: auto, cover, auto;
  padding-block-start: 120px;
  padding-block-end: var(--section-padding);
}
/*-----------------------------------*\
  #HERO
\*-----------------------------------*/

.hero {
  background-image: url("./images/hero-bg-bottom.png"),
    url("./images/hero-bg.png"), var(--gradient-1);
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: -280px bottom, center, center;
  background-size: auto, cover, auto;
  padding-block-start: 120px;
  padding-block-end: var(--section-padding);
}

.hero-content {
  margin-block-end: 50px;
}

.hero-subtitle {
  color: var(--chrome-yellow);
  font-family: var(--ff-source-sans-pro);
  font-size: var(--fs-7);
  margin-block-end: 15px;
}

.hero-title {
  color: var(--white);
  font-size: var(--fs-1);
  margin-block-end: 20px;
}

.hero-text {
  color: var(--white);
  font-size: var(--fs-8);
  margin-block-end: 30px;
}

/*-----------------------------------*\
  #ABOUT
\*-----------------------------------*/

.about-banner {
  margin-block-end: 30px;
}

.about .section-title {
  margin-inline: 0;
}

.about .underline::before {
  left: 0;
  transform: translateX(0);
}

.about-text {
  font-size: var(--fs-8);
  margin-block-end: 20px;
}

.stats-list {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 10px;
}

.stats-card {
  text-align: center;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #e9e3e3;
  cursor: pointer;
}

.stats-title {
  color: var(--st-patricks-blue);
  font-size: var(--fs-2);
  font-weight: var(--fw-700);
}

.stats-text {
  font-size: var(--fs-8);
}

/*-----------------------------------*\
  #SERVICE
\*-----------------------------------*/

.service-list {
  display: grid;
  gap: 30px;
}

.service-card {
  padding: 30px;
  box-shadow: var(--shadow);
  border-radius: var(--radius-12);
}

.service-card .card-icon {
  background-image: url("./images/service-banner-pattern.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: hsla(335, 87%, 53%, 0.12);
  aspect-ratio: 1 / 1;
  max-width: 165px;
  display: grid;
  place-content: center;
  margin-inline: auto;
  transition: var(--transition-1);
}

.service-card:hover .card-icon {
  background-color: var(--winter-sky);
}

.service-card .card-icon ion-icon {
  font-size: 5rem;
  color: var(--winter-sky);
  --ionicon-stroke-width: 20px;
  transition: var(--transition-1);
}

.service-card:hover .card-icon ion-icon {
  color: var(--white);
}

.service-card .title {
  text-align: center;
  margin-block-end: 15px;
}

.service-card .text {
  text-align: center;
  margin-block-end: 20px;
}

.service-card .card-btn {
  margin-inline: auto;
  padding: 15px;
  border: 1px solid var(--winter-sky);
  border-radius: 50%;
  color: var(--winter-sky);
  transition: var(--transition-1);
}

.service-card .card-btn:is(:hover, :focus) {
  color: var(--white);
  background-color: var(--winter-sky);
}

/*-----------------------------------*\
  #FEATURE
\*-----------------------------------*/

.features-list > li:first-child {
  margin-block-end: 30px;
}

.features-card {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.features-card .icon {
  background-image: var(--gradient-1);
  background-size: 200%;
  color: var(--white);
  min-width: max-content;
  max-width: max-content;
  padding: 14px 14px 9px 14px;
  border-radius: 50%;
}

.features-card .icon ion-icon {
  --ionicon-stroke-width: 20px;
}

.features-card .title {
  margin-block-end: 10px;
}

.features-banner {
  margin-block: 40px;
}

.features-banner > img {
  max-width: max-content;
  margin-inline: auto;
}

/*-----------------------------------*\
  #BLOG
\*-----------------------------------*/

.blog {
  padding-block-end: 120px;
}

.blog-list {
  display: grid;
  gap: 30px;
}

.blog-card {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #e9e3e3;
}

.blog-card .banner {
  border-radius: 40% 10% / 10% 30%;
  overflow: hidden;
  margin-block-end: 15px;
}

.blog-card .banner img {
  transition: var(--transition-2);
}

.blog-card .banner a:is(:hover, :focus) img {
  transform: scale(1.1);
}

.blog-card .title {
  color: var(--st-patricks-blue);
  font-size: var(--fs-6);
  line-height: 1.2;
  margin-block-end: 15px;
}

.blog-card .title > a {
  color: inherit;
}

.blog-card .title > a:is(:hover, :focus) {
  color: var(--razzmatazz);
}

.blog-card .text {
  margin-block-end: 15px;
}

.blog-card .meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  font-size: var(--fs-8);
  color: var(--purple-navy);
  font-weight: var(--fw-500);
  padding-block-end: 10px;
}

.blog-card .meta ion-icon {
  color: var(--winter-sky);
  font-size: 22px;
  --ionicon-stroke-width: 35px;
}

.publish-date,
.comment {
  display: flex;
  align-items: center;
  gap: 8px;
}

.comment {
  color: inherit;
  margin-inline-start: auto;
}

/*-----------------------------------*\
  #FOOTER
\*-----------------------------------*/

.footer {
  font-size: var(--fs-8);
}

.footer a {
  color: inherit;
}

.footer-top {
  background-image: url("./images/footer-bg.png"), var(--gradient-1);
  background-repeat: no-repeat;
  background-size: auto, 200%;
  background-position: center, center;
  color: var(--white);
}

.footer-brand {
  margin-block-end: 30px;
}

.footer-brand .logo {
  font-weight: var(--fw-700);
  margin-block-end: 15px;
}

.footer-brand .text {
  font-size: var(--fs-8);
  margin-block-end: 20px;
}

.social-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.footer-top .social-link {
  background-color: var(--white);
  color: var(--winter-sky);
  font-size: 18px;
  padding: 10px 12px 5px 12px;
  border-radius: 50%;
}

.footer-top .social-link:is(:hover, :focus) {
  background-image: var(--gradient-2);
  color: var(--white);
}

.footer-list:not(:last-child) {
  margin-block-end: 25px;
}

.footer-list-title {
  font-family: var(--ff-source-sans-pro);
  font-size: var(--fs-5);
  font-weight: var(--fw-700);
  margin-block-end: 15px;
}

.footer-link {
  padding-block: 5px;
}

:is(.footer-link, .footer-item-link):not(address):is(:hover, :focus) {
  text-decoration: underline;
}

.footer-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-block: 10px;
}

.footer-item-icon {
  background-image: var(--gradient-2);
  padding: 10px 12px 5px 12px;
  border-radius: 50%;
}

.footer-bottom {
  background-color: var(--space-cadet-2);
  padding: 20px;
  text-align: center;
  color: var(--white);
}

.copyright-link {
  display: inline-block;
  text-decoration: underline;
}

.copyright-link:is(:hover, :focus) {
  text-decoration: none;
}

/*-----------------------------------*\
  #GO TO TOP
\*-----------------------------------*/

.go-top {
  position: fixed;
  bottom: 0;
  right: 15px;
  background-color: var(--winter-sky);
  color: var(--white);
  padding: 14px 14px 9px 14px;
  border-radius: 50%;
  box-shadow: -3px 3px 15px var(--winter-sky_50);
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: var(--transition-1);
}

.go-top.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(-70px);
  transition: var(--transition-1);
}

/*-----------------------------------*\
  #WHATSAAP
\*-----------------------------------*/

.whatsaap {
  position: fixed;
  bottom: 15px;
  right: 15px;
  background-color: #25d366;
  color: var(--white);
  padding: 14px 14px 9px 14px;
  border-radius: 50%;
  box-shadow: -3px 3px 15px #25d366;
  z-index: 2;
}

/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for larger than 550px screen
 */

@media (min-width: 550px) {
  /**
   * REUSED STYLE
   */

  .container {
    max-width: 550px;
    margin-inline: auto;
  }

  .section-title {
    --fs-3: 3.6rem;
  }

  /**
   * HEADER
   */

  .header .btn {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-inline-start: auto;
  }

  /**
   * ABOUT
   */

  /* .stats-list {
    grid-template-columns: repeat(3, 1fr);
  } */

  /**
   * BLOG
   */

  .blog-card {
    display: grid;
    grid-template-columns: 0.75fr 1fr;
    gap: 20px;
    padding: 30px;
  }

  .blog-card .banner {
    margin-block-end: 0;
  }

  .blog-card .banner a {
    height: 100%;
  }

  /**
   * FOOTER
   */

  .footer-brand,
  .footer-list:not(:last-child) {
    margin-block-end: 0;
  }

  .footer-top .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px 50px;
  }
}

/**
 * responsive for larger than 768px screen
 */

@media (min-width: 768px) {
  /**
   * REUSED STYLE
   */

  .container {
    max-width: 720px;
  }

  /**
   * HERO
   */

  .hero {
    min-height: 600px;
    display: grid;
    place-items: center;
  }

  .hero-content {
    margin-block-end: 0;
  }

  .hero .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 30px;
  }

  /**
   * SERVICE
   */

  .service-list {
    grid-template-columns: 1fr 1fr;
  }

  /**
   * FEATURES
   */

  .features-list > li:first-child {
    margin-block-end: 0;
  }

  .features-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
  }

  /**
   * FOOTER
   */

  .footer-top .container {
    grid-template-columns: repeat(3, 1fr);
  }
}

/**
 * responsive for larger than 992px screen
 */

@media (min-width: 992px) {
  /**
   * CUSTOM PROPERTY
   */

  :root {
    /**
     * typography
     */

    --fs-1: 5.4rem;
  }

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 950px;
  }

  /**
   * HEADER
   */

  .header {
    padding-block: 20px;
  }

  .overlay,
  .nav-open-btn,
  .navbar-top {
    display: none;
  }

  .navbar,
  .navbar.active {
    all: unset;
    margin-inline-start: auto;
  }

  .header .btn {
    margin-inline-start: 0;
  }

  .navbar-list {
    display: flex;
    gap: 25px;
  }

  .navbar-item:not(:last-child) {
    border-bottom: none;
  }

  .navbar-link {
    color: var(--color);
  }

  /**
   * HERO
   */

  .hero {
    min-height: 700px;
  }

  /**
   * ABOUT
   */

  .about .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  /**
   * SERVICE
   */

  .service-list {
    grid-template-columns: repeat(3, 1fr);
  }

  /**
   * FEATURES
   */

  .features-list {
    grid-template-columns: 1fr;
  }

  .features .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  .features .section-title {
    grid-column: 1 / 4;
  }

  .features-banner {
    margin-block: 0;
    display: grid;
    place-items: center;
  }

  /**
   * FOOTER
   */

  .footer-top .container {
    grid-template-columns: repeat(4, 1fr);
  }

  .footer-brand {
    grid-column: 1 / 5;
  }

  .footer-brand .text {
    max-width: 45ch;
  }
}

/**
 * responsive for larger than 1200px screen
 */

@media (min-width: 1200px) {
  /**
   * REUSED STYLE
   */

  .container {
    max-width: 1200px;
  }

  .section-title {
    --fs-3: 4.6rem;
  }

  /**
   * HERO
   */

  .hero {
    min-height: 620px;
  }

  /**
   * BLOG
   */

  .blog-list {
    grid-template-columns: 1fr 1fr;
  }

  .blog-card {
    height: 100%;
  }

  .blog-card .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  /**
   * FOOTER
   */

  .footer-top .container {
    grid-template-columns: 1fr 0.5fr 0.7fr 0.5fr 1fr;
  }

  .footer-brand {
    grid-column: auto;
  }
}

/* pricing */
.pricing {
  background-image: url("./images/bg-bottom.svg"), url("./images/bg-top.svg");
  background-size: 280px 300px, 380px 530px;
  background-position: 0 110%, 100% 0%;
  background-repeat: no-repeat;
  background-color: hsl(240, 78%, 98%);
  text-align: center;
  font-family: var(--ff-poppins);
  font-weight: 700;
  max-width: 100%;
  overflow-x: hidden;
  letter-spacing: 0.5px;
}

.pricing hr {
  height: 1px;
  border: 0;
  color: #d2d2d2;
  background-color: #d2d2d2;
}

.pricing span {
  font-size: 20px;
  vertical-align: middle;
  padding-right: 0.2em;
}

.pricing h5 {
  margin: 15px;
  font-family: var(--ff-source-sans-pro);
}

.pricing h4.card-title {
  margin: 25px 0 15px 0;
  font-family: var(--ff-source-sans-pro);
}

.pricing .price {
  font-size: 2.5em;
  color: hsl(234, 14%, 30%);
  margin: 0 0 20px 0;
}

.pricing .master-price {
  margin-bottom: 25px;
}

.pricing .professional-card > h4 {
  margin-top: 40px;
}

.pricing .professional-card > .price {
  color: white;
  margin-top: 20px;
}

.pricing .title {
  padding: 2rem 0.2rem 0.2rem 0.2rem;
  color: hsl(233, 13%, 49%);
}

.pricing .title > h1 {
  margin-bottom: 10px;
}

.pricing .sub-title {
  margin-bottom: 0;
  margin-top: 0;
  color: hsl(234, 14%, 74%);
}

.pricing .text,
.pricing .card-title {
  color: hsl(233, 13%, 49%);
}

/* ------------ Grid ------------*/
.pricing .grid-parent {
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(3, 23%);
  justify-content: center;
}

/* ------------ Cards ------------*/
.pricing .card {
  border-radius: 8px;
  padding: 0 10%;
  box-shadow: 0px 0px 25px -8px hsl(240, 9%, 76%);
}

.pricing .basic-card,
.pricing .master-card {
  background-color: white;
  color: rgb(83, 83, 83);
}

.pricing .professional-card {
  background-image: linear-gradient(
    to bottom right,
    hsl(236, 72%, 79%),
    hsl(237, 63%, 64%)
  );
  color: white;
  height: 108%;
  place-self: center auto;
}

/* ------------ Buttons Style ------------*/
.pricing .button {
  background-image: linear-gradient(
    to right,
    hsl(236, 72%, 79%),
    hsl(237, 63%, 64%)
  );
  width: 100%;
  height: 35px;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 5px 30px;
  text-align: center;
  text-decoration: none;
  margin: 20px 0 25px 0;
  font-weight: 700;
  letter-spacing: 1.3px;
}

.pricing .professional-button {
  background-color: #ffffff;
  width: 100%;
  height: 35px;
  border: none;
  border-radius: 5px;
  color: hsl(237, 63%, 64%);
  padding: 5px 30px;
  text-align: center;
  text-decoration: none;
  margin-top: 15px;
  font-weight: 700;
  letter-spacing: 1.3px;
}

.pricing .pro-btn:hover {
  border: 1px solid white;
  background-image: linear-gradient(
    to right,
    hsl(236, 78%, 73%),
    hsl(237, 63%, 64%)
  );
  color: white;
  cursor: pointer;
}

.pricing .btn:hover {
  background-image: linear-gradient(
    to right,
    hsl(0, 0%, 100%),
    hsl(0, 0%, 100%)
  );
  color: hsl(237, 63%, 64%);
  border: 1px solid hsl(237, 63%, 64%);
  cursor: pointer;
}

/* The switch - the box around the slider */
.pricing .switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
  bottom: 3px;
  margin-left: 20px;
  margin-right: 20px;
}

/* Hide default HTML checkbox */
.pricing .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.pricing .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    to right,
    hsl(236, 78%, 73%),
    hsl(237, 63%, 64%)
  );
  transition: 0.4s;
}

.pricing .slider:hover {
  opacity: 0.5;
}

.pricing .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

.pricing input:checked + .slider {
  background-image: linear-gradient(
    to right,
    hsl(235, 82%, 80%),
    hsl(237, 67%, 67%)
  );
}

.pricing input:focus + .slider {
  box-shadow: 0 0 1px hsl(237, 63%, 64%);
}

.pricing input:checked + .slider:before {
  transform: translateX(13px);
}

/* Rounded sliders */
.pricing .slider.round {
  border-radius: 34px;
}

.pricing .slider.round:before {
  border-radius: 50%;
}

/* ------------ Footer ------------*/
.pricing footer {
  margin-top: 50px;
}

.pricing .attribution {
  font-size: 10px;
  text-align: center;
  display: block;
  color: grey;
  padding-bottom: 15px;
}

.pricing .attribution a {
  text-decoration: none;
  color: grey;
}

/* ------------ Mobile View: When the screen size is 800px or lower do this: ------------*/
@media screen and (max-width: 800px) {
  .pricing body {
    background-image: url("./images/bg-top.svg");
    background-size: 280px 310px;
    background-position: 140% 0%;
    font-size: 3vw;
  }

  .pricing span {
    font-size: 5vw;
  }

  .pricing .title {
    padding-top: 2rem;
  }

  .pricing .switch-section {
    padding: 3rem;
  }

  .pricing .grid-parent {
    margin-top: 20px;
    grid-template-columns: repeat(1, 90%);
    grid-row-gap: 6rem;
  }

  .pricing h4.card-title {
    margin: 30px 0 15px 0;
  }

  .pricing footer {
    margin-top: 30px;
  }
}

/* styling for pagination */
.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.pagination-button {
  background: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
}

.pagination-button.active {
  background: #51558a;
  color: white;
  border: 1px solid #51558a;
}

.pagination-button:disabled {
  background: #ddd;
  cursor: not-allowed;
}
/* Responsive adjustments */
@media (max-width: 768px) {
  .pagination {
    flex-wrap: wrap; /* Allow buttons to wrap to the next line */
    justify-content: flex-start; /* Align buttons to the start on small screens */
  }
  .pagination-button {
    margin: 5px; /* Adjust margin for better spacing on small screens */
  }
}

/* pages -- of --*/
.page-indicator {
  margin: 10px 0;
  font-size: 16px;
  color: #1f2470;
  display: flex;
  justify-content: space-between;
  border: 1px solid #dbd9d9;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px;
}

/* image loader starts here......*/
.image-placeholder {
  width: 750px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* image loader ends here......*/
/* TrustedBrands.css */
.trusted-brands {
  text-align: center;
  padding: 0px 20px;
  overflow: hidden;
}

.trusted-brands h2 {
  font-size: 14px;
  margin-bottom: 10px;
  color: #828181;
}

.brands-container {
  width: 100%;
  overflow: hidden;
}

.brands-scroll {
  display: flex;
  animation: scroll 20s linear infinite;
}

.brand-logo {
  width: 150px;
  height: 70px;
  object-fit: contain;
  margin-right: 20px;
  transition: transform 0.3s ease;
}

.brand-logo:hover {
  transform: scale(1.1);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
